import React from "react";
import {iCards} from "../components/iCards";
import {Link} from "react-router-dom";

interface iState {
    posScore: number;
    negScore: number;
    rating: string;
    langEn: boolean;
}

export default class SpelUitslag extends React.Component<any, iState> {

    storage: any;

    constructor(props: any) {
        super(props);
        this.storage = this.props.storage;
        this.state = {
            posScore: 0,
            negScore: 0,
            langEn: (localStorage.getItem('lang') && localStorage.getItem('lang') === "en" ? true : false),
            rating: ''
        }
    }

    componentDidMount() {
        this.checkStack();
    }

    checkStack = () => {
        let rightStack = this.storage.getStorage().swipedRightCards;
        let posScore = 0;
        let negScore = 0;

        for(let card of rightStack){
            if(card.status === "negatief"){
                negScore+=card.value;
            }
            if(card.status === "positief"){
                posScore+=card.value;
            }
        }

        this.setState({posScore: this.state.posScore+posScore, negScore: this.state.negScore+negScore});
    }

    verzendingCheck = () => {
        this.storage.setPosNegStatus(this.state.posScore >= this.state.negScore ? 'positief' : 'negatief');
    }

    render(){
        this.storage.setPosNegStatus(this.state.posScore >= this.state.negScore ? 'positief' : 'negatief');
        return <div className={this.state.posScore >= this.state.negScore ? 'results posrating' : 'results negrating'}>

            <h1 className="statustitle">{this.state.langEn ? ("Your current pension status is:") : ("Jouw huidige pensioen status is:")}</h1>
            <h1 className="result">{this.state.langEn ? (this.state.posScore >= this.state.negScore ? 'Positive' : 'Negative') : (this.state.posScore >= this.state.negScore ? 'Positief' : 'Negatief')}</h1>

            <br/>

            {this.state.posScore >= this.state.negScore ? (<p>
                <h3>{this.state.langEn ? ("This means you have a good understanding of your pension situation") : ("Dit betekent dat je goed inzicht hebt in jouw pensioenstatus")}</h3>
                {this.state.langEn ? ("Would you like to get more insights or want to know what to do? Go to") : ("Wil je meer inzicht of weten wat je zou moeten doen? Ga dan naar")}
            </p>) : (
                <p><h3>{this.state.langEn ? ("This means you have insufficient understanding of your pension situation.") : ("Dit betekent dat je onvoldoende inzicht hebt in jouw pensioensituatie")}</h3> {this.state.langEn ? ("Action time, grow your knowledge, so you know what to do. Go to") : ("Kom in actie, zorg dan je inzicht krijgt om te weten wat je zou moeten doen. Ga naar ")}</p>)}
            <a href="https://pensioenvitamines.nl/">https://pensioenvitamines.nl/</a><br/><br/><br/><br/>

            <div className="resultbuttons">
                <Link className="bigredbuttonthree" onClick={this.verzendingCheck} to="/verzending">{this.state.langEn ? ("Get game results") : ("Ontvang Speluitslag")}</Link>
            </div>

        </div>
    }


}
