import React from "react";
import {Link} from "react-router-dom";

export default class GegevensVerzonden extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            langEn: (localStorage.getItem('lang') && localStorage.getItem('lang') === "en" ? true : false),
        }
    }

    render(){
        return <div>
            <div className="smartresizebox">
                <h1>
                    {this.state.langEn ? ("Results have been saved") : ("Resultaten zijn opgeslagen")}
                </h1>
                <p>
                    {this.state.langEn ? (<span>Thank you for playing the Pension game! <br /> <br /> If you've entered your e-mail address in the previous form, you'll receive the results via email.</span>) : (<span>Bedankt voor het spelen van het Pensioenspel! <br /> <br /> De resultaten worden per email naar u toegestuurd mits u deze heeft ingevuld.</span>)}

                </p>
            </div>

            <div className="poweredby">
                {this.state.langEn ? ("Powered by") : ("Dit is een initiatief van")}
                <div className="pasveerlogo"><a href="https://www.pasveerpensioenadvies.nl"><img src="./pasveer.jpg" alt="Pasveer pensioenadvies" /></a></div>
            </div>
        </div>



    }


}
