import React from "react";
import {iCards} from "../components/iCards";
import axios from "axios";
import IndicatorDots from "../components/IndicatorDots";
import {Link} from "react-router-dom";

interface iState{
    voornaam: string;
    tussenvoegsel: string;
    achternaam: string;
    emailadres: string;
    loadingSubscription: boolean;
    algemenevoorwaarden: boolean;
    nieuwsbrief: boolean;
    showPopup: boolean;
    toggleVoorwaarden: boolean;
    resetGame: boolean;
    debugResponse: string;
    sent: boolean;
    langEn: boolean;
    waitingForResponse: boolean;
}

export default class GegevensVerzending extends React.Component<any, iState> {

    storage: any;

    constructor(props: any) {
        super(props);
        this.storage = this.props.storage;
        this.state = {
            voornaam: '',
            tussenvoegsel: '',
            achternaam: '',
            emailadres: '',
            loadingSubscription: false,
            algemenevoorwaarden: false,
            nieuwsbrief: false,
            showPopup: false,
            toggleVoorwaarden: false,
            resetGame: false,
            debugResponse: 'test',
            waitingForResponse: false,
            sent: false,
            langEn: (localStorage.getItem('lang') && localStorage.getItem('lang') === "en" ? true : false),
        };
    }

    render() {
            if(this.state.loadingSubscription){
                return <div className="smartresizebox">
                    <h1>
                        {this.state.langEn ? ("Loading...") : ("Aan het laden...")}
                    </h1>
                </div>
            }else{
                return <div>
                    <div className="gegevensbox">
                        {this.state.toggleVoorwaarden ? (<div>
                                <div className="gegevensboxtitle">
                                    Algemene voorwaarden  <br />
                                </div>

                                <div className="algemenevoorwaarden">
                                    Beste pensioenspel speler,<br /><br />
                                    Graag attenderen wij u op uw privacy. Uw naam, mailadres en speluitslag worden
                                    opgeslagen voor nader contact. Alleen de eigenaar van Pasveer pensioenadvies heeft toegang tot
                                    deze gegevens. Dit spel is vrijblijvend en na het invullen van uw gegevens ontvangt u een e-mail met
                                    een speluitslag. Mocht u zich hebben aangemeld voor de nieuwsbrief ontvangt u deze per e-mail en
                                    zal u geen andere mails ontvangen waar u geen toestemming voor heeft verleent.<br /><br />
                                    Gaat u akkoord met het opslaan van uw spelgegevens?<br /><br />
                                    Gaat u akkoord met de verwerking van uw persoonsgegevens?<br /><br />
                                    U kunt u eenvoudig afmelden voor de nieuwsbrief en uw persoonsgegevens laten verwijderen via
                                    een linkje onderaan onze e-mails.<br /><br />
                                    Doelen verwerking van gegevens:<br /><br />
                                    Pasveer Pensioenadvies heeft uw gegevens nodig om contact met u op te nemen en te kunnen
                                    adviseren op het gebied van pensioen. Uw speluitslag wordt opgeslagen, indien u akkoord geeft, om
                                    zo gepast advies te kunnen verlenen.<br /><br />
                                    Wij verzamelen gegevens over uw privé omstandigheden gerelateerd aan pensioenopbouw. Wij
                                    verzamelen uw naam en mailadres om verder contact te leggen en uw speluitslag te
                                    koppelen aan uw identiteit.<br /><br />
                                    U heeft ten alle tijden het recht om uw toestemming in te trekken, hierna zullen uw speluitslag en de
                                    verbonden persoonsgegevens verwijderd worden uit onze systemen.
                                </div>

                                <div className="algemenevoorwaardenbutton">
                                    <Link className="bigredbuttonfive" onClick={this.toggleVoorwaarden} to="#">Ga terug</Link>
                                </div>
                            </div>

                        ) :( <div className="wrapper-box1">
                            <div className="gegevensboxtitle">
                                {this.state.langEn ? ("Personal Details") : ("Verzending gegevens")}
                            </div>

                            <div className="gegevensuitleg">
                                {this.state.langEn ? ("To get all the game results, you must agree to the terms of use and leave your mail address.") : ("Om alle gegevens te ontvangen moet u akkoord gaan met de algemene voorwaarden en uw e-mailadres achterlaten.")}
                            </div>

                            <form className="formsup" onSubmit={this.handleSubmit}>
                                <div className="naastelkaar">
                                    <label className="gegevensname">
                                        <input required className="gegevensinput gegevensinputhalf" placeholder={this.state.langEn ? ("Firstname*") : ("Voornaam*")} type="text" value={this.state.voornaam} maxLength={50} onChange={this.handleChange} name="voornaam" />
                                    </label>

                                    <label className="gegevensname">
                                        <input className="gegevensinput gegevensinputhalf" placeholder={this.state.langEn ? ("Infix") : ("Tussenvoegsel")} type="text" value={this.state.tussenvoegsel} maxLength={15} onChange={this.handleChange} name="tussenvoegsel" /> <br />
                                    </label>
                                </div>

                                <label className="gegevensname">
                                    <input required className="gegevensinput" placeholder={this.state.langEn ? ("Lastname*") : ("Achternaam*")} type="text" name="achternaam" value={this.state.achternaam} maxLength={52} onChange={this.handleChange} /> <br />
                                </label>

                                <label className="gegevensemail">
                                    <input required className="gegevensinput" placeholder={this.state.langEn ? ("Mail Address*") : ("E-mail*")} type="email" name="emailadres" value={this.state.emailadres} maxLength={320} onChange={this.handleChange} /> <br />
                                </label>

                                <label className="checkbox">
                                    <input required type="checkbox" name="algemenevoorwaarden" onChange={this.handleChangeCheck} />

                                    <span className="checkmark"></span>

                                    {this.state.langEn ? (<span>I accept the <Link onClick={this.toggleVoorwaarden} to="#">terms of use*</Link> <br /> <br /></span>) : (<span>Ik ga akkoord met de <Link onClick={this.toggleVoorwaarden} to="#">algemene voorwaarden*</Link> <br /> <br /></span>)}

                                </label>

                                <label className="checkbox">
                                    <input type="checkbox" name="nieuwsbrief" onChange={this.handleChangeCheck} />

                                    <span className="checkmark"></span>

                                    {this.state.langEn ? ("I want to receive the newsletter") : ("Ik wil de nieuwsbrief ontvangen")} <br /> <br />
                                </label>

                                {!this.state.waitingForResponse ? (<input disabled={(this.state.algemenevoorwaarden ? false: true)} className="bigredbuttonfour" type="submit" value={this.state.langEn ? ("Send Results") : ("Gegevens Verzenden")} />): (<input disabled className="bigredbuttonfour" value={this.state.langEn ? ("Saving Data... Please Wait...") : ("Aan het opslaan, moment geduld")} />)}

                            </form>
                        </div>)}
                    </div>
                </div>
            }

    }

    private handleSubmit = (event: any) => {
        event.preventDefault();
        if(!this.state.waitingForResponse){
            this.setState({waitingForResponse: true});
            let {voornaam, tussenvoegsel, achternaam, emailadres, nieuwsbrief} = this.state;

            axios.post('https://cms.pensioenspel.nl/api/speler/edit', {
                "id": localStorage.getItem('anonId'),
                "firstname": voornaam,
                "middlename": tussenvoegsel ? tussenvoegsel : 'geen',
                "lastname": achternaam,
                "email": emailadres,
                "newsletter": nieuwsbrief,
                "resulttext": this.storage.getStorage().pensResult,
                "resultright": this.storage.getStorage().swipedRightCards,
                "resultleft": this.storage.getStorage().swipedLeftCards,
                "company": localStorage.getItem('compid'),
            }).then((response) => {
                console.log(response.data);
                localStorage.removeItem('compid');
                localStorage.removeItem('anonId');
                localStorage.removeItem('compiddate');
                this.setState({waitingForResponse: false});
                setTimeout(() => {
                    window.location.href = "/verzonden";
                },300)
            })
        }

    }


    private toggleVoorwaarden = () => {
        this.setState({toggleVoorwaarden: !this.state.toggleVoorwaarden})
    }

    private handleChange = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }

    private handleChangeCheck = (event: any) => {
        const value = event.target.checked;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
}
