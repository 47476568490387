import React from "react";
import {Link} from "react-router-dom";

export default class ShuffleAnimationLoading extends React.Component<any, any> {

    arrayCards: any;
    travel: boolean;
    rotation: number;

    constructor(props: any) {
        super(props);
        this.arrayCards = new Array(0,1,2,3,4,5,6,7,8,9);
        this.travel = true;
        this.rotation = 0;
        console.log(this.arrayCards);
    }

    componentDidMount() {
        this.toggleAnimation();
    }

    render(){
        return <div className="deck">
            <div className="fakeStack">
                {this.arrayCards.map((card: any, index: any) => {
                    return<div className={(this.travel ? "fakecard loadingcardanim": "fakecard loadingcardanim location")} style={{transform: 'rotate(' + (1.075 * (18 - (Math.floor((index / 10 * 100) / 10)) - index)) + 'deg)', top: '-1000px'}}/>
                })
                }
            </div>
        </div>
    }

    private toggleAnimation = () => {
        console.log(this.travel);

        console.log(this.travel);
    }


}
