import React from "react";
import {Link} from "react-router-dom";
import axios from "axios";

interface iState {
    swipedLeft: Array<any>;
    swipedRight: Array<any>;
    langEn: boolean;
    show: string;
    waitingForResponse: boolean;
    posScore: number;
    negScore: number;
}

export default class Resultaten extends React.Component<any, iState> {
    storage: any;

    constructor(props: any) {
        super(props);
        this.storage = this.props.storage;
        this.storage.loadStorage();
        this.state = {
            swipedLeft: this.storage.storage.swipedLeftCards,
            swipedRight: this.storage.storage.swipedRightCards,
            langEn: (localStorage.getItem('lang') && localStorage.getItem('lang') === "en" ? true : false),
            show: "rechts",
            waitingForResponse: false,
            posScore: 0,
            negScore: 0,
        }


    }

    moveTo = (id: number, fromA: Array<any>, to: string) => {
        let newArr = [];
        let tobeMoved;
        let oldStore = this.storage.storage;

        for (let card of fromA) {
            if (card.id !== id) {
                newArr.push(card);
            } else {
                tobeMoved = card;
            }
        }

        if (to === "right") {
            let oldStateArr = this.state.swipedRight;
            oldStateArr.push(tobeMoved);
            oldStore.swipedRightCards = oldStateArr;
            oldStore.swipedLeftCards = newArr;
            this.setState({swipedRight: oldStateArr, swipedLeft: newArr});
        }

        if (to === "left") {
            let oldStateArr = this.state.swipedLeft;
            oldStateArr.push(tobeMoved);
            oldStore.swipedRightCards = newArr;
            oldStore.swipedLeftCards = oldStateArr;
            this.setState({swipedRight: newArr, swipedLeft: oldStateArr});
        }

        this.storage.setStorage(oldStore);

        this.storage.save();
    }

    setShowTo = (value: string) => {
        this.setState({show: value});
    }

    checkStack = () => {
        let rightStack = this.storage.getStorage().swipedRightCards;
        let posScore = 0;
        let negScore = 0;

        for(let card of rightStack){
            if(card.status === "negatief"){
                negScore+=card.value;
            }
            if(card.status === "positief"){
                posScore+=card.value;
            }
        }

        this.setState({posScore: this.state.posScore+posScore, negScore: this.state.negScore+negScore});
    }

    private sendAnon = () => {
        if (!this.state.waitingForResponse) {
            let t = this;
            this.setState({waitingForResponse: true});
            let success = false;
            this.checkStack();

            let today = new Date();

            let dd = today.getDate();

            let mm = today.getMonth()+1;
            let yyyy = today.getFullYear();


            axios.post('https://cms.pensioenspel.nl/api/speler/add', {
                "firstname": 'Anoniem',
                "middlename": 'op',
                "lastname": ''+dd+'-'+mm+'-'+yyyy,
                "email": 'anoniem@pensioenspel.nl',
                "newsletter": false,
                "resulttext": this.state.posScore >= this.state.negScore ? 'positief' : 'negatief',
                "resultright": this.storage.getStorage().swipedRightCards,
                "resultleft": this.storage.getStorage().swipedLeftCards,
                "company": localStorage.getItem('compid'),
            })
            .then(function (response) {
                // handle success
                localStorage.setItem('anonId', response.data);
                t.setState({waitingForResponse: false});
                window.location.href = "/speluitslag";
            })
        }
    }

    render() {
        return <div className={this.state.show === "links" ? "darkers" : ""}>

            <div className="welniet">
                <div className="wel" onClick={() => this.setShowTo("links")}>
                    <div className="thumbsup thumbsdown"></div>
                    {this.state.langEn ? ("NO") : ("NIET")}
                </div>
                <div className="niet" onClick={() => this.setShowTo("rechts")}>
                    <div className="thumbsup"></div>
                    {this.state.langEn ? ("YES") : ("WEL")}
                </div>
            </div>

            <div className="welniet-box">

                {this.state.show === "links" ? (<div>
                    <ul className="welniet-list">
                        {this.state.swipedLeft.map((card: any, index: any) => {
                            return <li key={"a" + index}>
                                <div>
                                    <div className="welnietheader">
                                        <img className="welnietimage welnietimageniet" width="50px" height="50px"
                                              src={"https://cms.pensioenspel.nl/" + card.card_icon}/><b
                                        className="cardtitlething">{this.state.langEn ? card.text_front_en : card.text_front}</b><br/><br/>
                                    </div>
                                    <div className="welnietbody">
                                        <b>{this.state.langEn ? ("Description") : ("Beschrijving")}</b><br/>
                                        <p>{this.state.langEn ? card.text_back_en : card.text_back}</p>
                                        <i>
                                            <div className="welnietbutton welnietbuttonwel" onClick={() => this.moveTo(card.id, this.state.swipedLeft, "right")}>{this.state.langEn ? (<span>Move card to <b>YES</b></span>) : (<span>Verplaats naar <b>WEL</b></span>)}</div>
                                        </i>
                                    </div>

                                </div>
                            </li>
                        })}
                    </ul>
                </div>) : (<div>
                    <ul className="welniet-list">
                        {this.state.swipedRight.map((card: any, index: any) => {
                            // @ts-ignore
                            return <li key={"aa" + index}>
                                <div>
                                    <div className="welnietheader">
                                        <img className="welnietimage welnietimagewel" width="50px" height="50px"
                                              src={"https://cms.pensioenspel.nl/" + card.card_icon}/><b
                                        className="cardtitlething">{this.state.langEn ? card.text_front_en : card.text_front}</b><br/><br/>
                                    </div>
                                    <div className="welnietbody">
                                        <b>{this.state.langEn ? ("Description") : ("Beschrijving")}</b><br/>
                                        <p>{this.state.langEn ? card.text_back_en : card.text_back}</p>
                                        <i>
                                            <div className="welnietbutton welnietbuttonniet" onClick={() => this.moveTo(card.id, this.state.swipedRight, "left")}>
                                                {this.state.langEn ? (<span>Move card to <b>NO</b></span>) : (<span>Verplaats naar <b>NIET</b></span>)}
                                            </div>
                                        </i>
                                    </div>
                                </div>

                            </li>
                        })}
                    </ul>
                </div>)}
            </div>
            <div className="bigredbutton resultbutton" onClick={this.sendAnon}>
                {!this.state.waitingForResponse ? (this.state.langEn ? ("Finish game") : ("Spel Afronden!")) : (this.state.langEn ? ("Loading Results...") : ("Resultaten aan het laden..."))}
            </div>
            {/*<Link to="/speluitslag" className="bigredbutton resultbutton">{this.state.langEn ? ("Finish game") : ("Spel Afronden!")}</Link>*/}
        </div>;
    }

}
