import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";

interface iState{
    dataRes: any;
}


export default class InviteChecker extends React.Component<iState, any> {
    compUUID: any;
    constructor(props: any) {
        super(props);
        this.state = {dataRes: '', redirect: false}
        this.compUUID = props.match.params.uuid;
    }

    componentDidMount() {
        let t = this;
        axios.get('https://cms.pensioenspel.nl/api/invite/'+this.compUUID).then(function (response) {
            t.setState({dataRes: response.data});

            localStorage.setItem('compid', response.data.id);
            localStorage.setItem('compiddate', new Date().toUTCString());
            localStorage.setItem('lang', "nl");
            setTimeout(() => {
                window.location.href = "/";
            }, 3000);

        }).catch((err: any) => {
            localStorage.removeItem('compid');
            localStorage.removeItem('compiddate');
            window.location.href = "/aanmeldingen-overschreven";
        });
    }

    render() {
        if(!this.state.dataRes.id && !this.state.redirect){
            return <div className="invitewelcome">
                <div className="loadingcard">
                    <div className="loadingcardimage"> </div>
                </div>
            </div>
        }else if(this.state.dataRes.id && !this.state.redirect){
            return <div className="invitewelcome">
                <div className="loadingcard">
                    <div className="loadingcardimage"> </div>
                </div>
                <p>U gebruikt een uitnodiging voor het bedrijf: <b>{this.state.dataRes.bedrijf_naam}</b></p>
            </div>;
        }
    }
}