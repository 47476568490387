import React from 'react';
import './App.css';
import CardDeck from "./pages/CardDeck";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import SpelUitleg from "./pages/SpelUitleg";
import {StorageEngine} from "./components/StorageEngine";
import SpelUitslag from "./pages/SpelUitslag";
import Resultaten from "./pages/WelNietPagina";
import GegevensVerzending from "./pages/GegevensVerzending";
import InviteChecker from "./components/InviteChecker";
import TeVeelAanmelding from "./pages/TeVeelAanmelding";
import GegevensVerzonden from "./pages/GegevensVerzonden";
import ShuffleAnimationLoading from "./pages/ShuffleAnimationLoading";

export default class App extends React.Component<any, any> {
    storage: any;
    constructor(props: any) {
        super(props);
        this.storage = new StorageEngine();
    }



    render() {
        const isToday = (someDate: any) => {
            const today = new Date()
            return someDate.getDate() == today.getDate() &&
                someDate.getMonth() == today.getMonth() &&
                someDate.getFullYear() == today.getFullYear()
        }
        if(!localStorage.getItem('compid') && !localStorage.getItem('compiddate')){
            return(
                <div className="">
                    <Router>
                        <Switch>
                            <Route path="/verzonden" component={GegevensVerzonden}/>
                            <Route path="/u/:uuid" component={InviteChecker} />
                            <Route path="/aanmeldingen-overschreven" component={TeVeelAanmelding} />
                            <Route>
                                <div className="niettoegestaan">
                                    <h1>Oeps</h1>
                                    <p>Helaas mag u zonder een uitnodigingslink deze website niet betreden. Wilt u een uitnodigingslink ontvangen? Ga naar <a href="http://www.pensioenvitamines.nl/">pensioenvitamines.nl</a> en neem contact met ons op.</p>
                                </div>
                            </Route>
                        </Switch>
                    </Router>
                </div>
            )

        }else if(isToday(new Date(localStorage.getItem('compiddate')+''))){
            return (
                <div className="">
                    <Router>
                        <Switch>
                            <Route path="/shuffle">
                                <ShuffleAnimationLoading storage={this.storage}/>
                            </Route>
                            <Route path="/spel">
                                <CardDeck storage={this.storage}/>
                                <div className="wave"></div>
                            </Route>
                            <Route path="/" exact>
                                <SpelUitleg/>
                            </Route>
                            <Route path="/resultaten">
                                <Resultaten storage={this.storage}/>
                            </Route>
                            <Route path="/speluitslag">
                                <SpelUitslag storage={this.storage}/>
                            </Route>
                            <Route path="/verzending">
                                <GegevensVerzending storage={this.storage}/>
                            </Route>
                            <Route path="/aanmeldingen-overschreven" component={TeVeelAanmelding} />
                            <Route path="/u/:uuid" component={InviteChecker} />
                        </Switch>
                    </Router>
                </div>
            );
        }else{
            return(
                <div className="">
                    <Router>
                        <Switch>
                            <Route path="/u/:uuid" component={InviteChecker} />
                            <Route>
                                <div className="niettoegestaan">
                                    <h1>Oeps</h1>
                                    <p>Helaas mag u zonder een uitnodigingslink deze website niet betreden. Wilt u een uitnodigingslink ontvangen? Ga naar <a href="http://www.pensioenvitamines.nl/">pensioenvitamines.nl</a> en neem contact met ons op.</p>
                                </div>
                            </Route>
                        </Switch>
                    </Router>
                </div>
            )
        }
    }
}
