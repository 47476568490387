// @ts-nocheck
import React from "react";
import {Link} from "react-router-dom";
import TinderCard from "react-tinder-card";
import Carousel from 're-carousel';
import IndicatorDots from "../components/IndicatorDots";
import Wave1 from '../assets/newWave/newwave2-03.png';
import Wave2 from '../assets/newWave/newwave2-04.png';
import Wave3 from '../assets/newWave/newwave2-05.png';
import Wave4 from '../assets/newWave/newwave2-06.png';
import Wave5 from '../assets/newWave/newwave2-09.png';
import Wave6 from '../assets/newWave/newwave2-07.png';
import Wave7 from '../assets/newWave/newwave2-08.png';

import PillenDame from '../assets/pillenplaatjes/Pillen-09.png';
import PillenJongen from '../assets/pillenplaatjes/Pillen-10.png';
import PillenHoedje from '../assets/pillenplaatjes/Pillen-11.png';
import WelNietShuffle from '../assets/pillenplaatjes/Pillen-12.png';
import WelNietShuffleEN from '../assets/pillenplaatjes/yes_no_en-02.png';
import WelNietKnoppen from '../assets/pillenplaatjes/Pillen-13.png';
import WelNietKnoppenEN from '../assets/pillenplaatjes/yes_no_en-03.png';
import DubbelePillenDame from '../assets/pillenplaatjes/Pillen-14.png';
import upswipehelp from '../assets/upswipehelp1.png';
import axios from "axios";

interface iState {
    currentStep: number;
}

export default class SpelUitleg extends React.Component<any, iState> {
    constructor(props: any) {
        super(props);

        this.state = {
            currentStep: 0,
            langEn: localStorage.getItem('lang') && localStorage.getItem('lang') === "en"
        }
    }

    langSwitch = () => {
        if(localStorage.getItem('lang') && localStorage.getItem('lang') === "nl"){
            localStorage.setItem('lang', "en");
            window.location.href = "/";
        }else{
            localStorage.setItem('lang', "nl");
            window.location.href = "/";
        }
    }


    render() {
        return <div>
            <div className="langswitch" onClick={this.langSwitch}>
                {localStorage.getItem('lang') && localStorage.getItem('lang') === "nl" ? (<span><div className="bigredbutton">Change Language to <b>English</b></div></span>) : (<span><div className="bigredbutton">Zet de taal naar <b>Nederlands</b></div></span>)}
            </div>
        <div className="car-wrapper">

            <Carousel auto widgets={[IndicatorDots]} className="cassie">
                <div className="cassie-inner">
                    <div className="wavetext hoedjetekst">
                        <h1>
                            {this.state.langEn ? ("Welcome") : ("Welkom")}
                        </h1>
                        {this.state.langEn ? ("at the pension game") : ("bij het pensioenspel")}

                    </div>
                    <img src={PillenDame} width="200px" className="pillendame1" />
                    <div className="newwave2">
                        <img src={Wave1} width="100%" height="775x"/>
                    </div>
                    {/*<img className="newwaveblock" src="https://placehold.it/400x300" width="100%"/>*/}
                </div>
                <div className="cassie-inner">
                    <div className="wavetext hoedjetekst">
                        {this.state.langEn ? ("This game provides insight into your pension") : ("Dit spel geeft inzicht in jouw pensioen")}
                    </div>
                    <img src={PillenJongen} width="200px" className="pillenman1" />
                    <div className="newwave2">
                        <img src={Wave2} width="100%" height="775x"/>
                    </div>
                </div>
                <div className="cassie-inner">
                    <div className="wavetext hoedjetekst">
                        {this.state.langEn ? ("Each cart holds a statement which does or doesn’t apply to your situation") : ("Elke kaart heeft een stelling die wel of niet bij jouw situatie past")}

                    </div>
                    <img src={PillenHoedje} width="120px" className="pillenhoedje" />
                    <div className="newwave2">
                        <img src={Wave3} width="100%" height="775x"/>
                    </div>
                </div>
                <div className="cassie-inner">
                    <div className="wavetext">
                        {this.state.langEn ? ("When a card does apply to your situation, swipe right. If this is not the case swipe left") : ("Wanneer een kaart van toepassing is, swipe deze dan naar rechts. Indien dit niet het geval is, swipe dan naar links")}

                    </div>
                    <img src={!this.state.langEn ? WelNietShuffle : WelNietShuffleEN} width="400px" className="welnietvoorbeeld1" />
                    <div className="newwave2">
                        <img src={Wave4} width="100%" height="775x"/>
                    </div>
                </div>
                <div className="cassie-inner">
                    <div className="wavetext">
                        {this.state.langEn ? ("To see the backside of the card, swipe up") : ("Om de achterkant met extra informatie te zien, swipe de kaart dan omhoog")}

                    </div>
                    <img src={upswipehelp} width="175px" className="upswipevoorbeeld" />
                    <div className="newwave2">
                        <img src={Wave5} width="100%" height="775x"/>
                    </div>
                </div>
                <div className="cassie-inner">
                    <div className="wavetext">
                        {this.state.langEn ? ("After you’ve swiped all the cards, you can find them back in an overview page. Where you can make optionally changes") : ("Nadat je alle kaarten hebt geswiped, kan je deze terugvinden in een overzicht en hier kun je eventueel jouw keuzes ongedaan maken")}

                    </div>
                    <img src={!this.state.langEn ? WelNietKnoppen : WelNietKnoppenEN} width="400px" className="welnietvoorbeeld2" />
                    <div className="newwave2">
                        <img src={Wave6} width="100%" height="775x"/>
                    </div>
                </div>
                <div className="cassie-inner">
                    <div className="wavetext lastwave">
                        {this.state.langEn ? ("Everything clear? ") : ("Alles duidelijk?")}<br/>
                        {this.state.langEn ? ("Turn on your volume.") : ("Zet je geluid dan aan.")}<br/><br/>
                        <Link to="/spel" className="bigredbutton bigredfix">{this.state.langEn ? ("Let's begin") : ("Laten we beginnen")}</Link>
                    </div>
                    <img src={DubbelePillenDame} width="150px" className="pillendame1 dubbelepillen" />
                    <div className="newwave2">
                        <img src={Wave7} width="100%" height="775x"/>
                    </div>
                </div>
            </Carousel>
        </div>
        </div>
    }

}
