interface iStorage {
    swipedLeftCards: Array<Card>;
    swipedRightCards: Array<Card>;
    pensResult: string;
}


interface Card {
    id: number;
    name: string;
    image: string;
    text_front: string;
    text_back: string;
    status: string;
    turned: boolean;
}


export class StorageEngine{
    storage: iStorage = {} as iStorage;

    constructor() {
        this.loadStorage();
        // this.reset();
        // this.save();
    }

    getStorage(): iStorage{
        return this.storage;
    }

    setPosNegStatus(status: string){
        this.storage.pensResult = status;
        this.save();

    }

    setStorage(newStorage:iStorage){
        this.storage = newStorage;
        this.save();
    }

    loadStorage(){
        if(localStorage.getItem('data') !== '' && localStorage.getItem('data') != null){
            this.setStorage(JSON.parse(localStorage.getItem('data') as string));
        }else{
            this.storage = {
                pensResult: '',
                swipedLeftCards: [],
                swipedRightCards: []
            }
        }
    }

    reset(){
        this.storage = {
            pensResult: '',
            swipedLeftCards: [],
            swipedRightCards: []
        }
    }

    save(){
        localStorage.setItem('data', JSON.stringify(this.storage));
    }

    swipedLeft(newCard: Card) {
        this.loadStorage();
        let leftStorage = this.storage;
        console.log(leftStorage.swipedLeftCards.length);
        if(leftStorage.swipedLeftCards.length < 0){
            leftStorage.swipedLeftCards = [];
        }
        leftStorage.swipedLeftCards.push(newCard);
        console.log(leftStorage);
        this.setStorage(leftStorage);
    }

    swipedRight(newCard: Card) {
        this.loadStorage();
        let rightStorage = this.storage;

        if(!rightStorage.swipedRightCards){
            rightStorage.swipedRightCards = [];
        }
        rightStorage.swipedRightCards.push(newCard);

        this.setStorage(rightStorage);
    }
}