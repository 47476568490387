import React from "react";
import {Route} from "react-router-dom";

export default class TeVeelAanmelding extends React.Component<any, any> {

    render() {
        return  <div className="niettoegestaan">
            <h1>Jeetje</h1>
            <p>Dat ging snel, deze link heeft de maximale aanmeldingen bereikt.</p>
        </div>
    }
}