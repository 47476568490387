// @ts-nocheck
import React, {useEffect, useRef} from "react";
import TinderCard from "react-tinder-card";
import axios from "axios";
import {Link, BrowserRouter} from "react-router-dom";
import Carousel from 're-carousel';
import IndicatorDots from "../components/IndicatorDots";

import UitlegSwipe from "../assets/nietwelhelp1.png";
import UitlegSwipeEN from "../assets/nietwelhelp2_en-01.png";
import UitlegTitle from "../assets/nietwelhelp2.png";
import UitlegTitleEN from "../assets/nietwelhelp22_en-04.png";

interface Card {
    id: number;
    name: string;
    image: string;
    text_front: string;
    text_back: string;
    status: string;
    value: number; // Weging
    turned: boolean;
}

interface iState {
    deckOfCards: Array<any>;
    cardIndex: number;
    loading: boolean;
    preventSwipe:  boolean;
    lastSwipeDirection: string;
    helpToggle: boolean;
    currentHelpPage: number;
    helpAnimation: boolean;
    helpAnimationButton: boolean;
    topAnimation: boolean;
    rotationAnimation: boolean;
    langEn: boolean;
}

const uitleg = [
    {
        index: 1,
        description: 'Per kaart ga je bepalen of de getoonde kaart bij jou past of niet. Als een kaart voor jou toepasselijk is, swipe je deze naar rechts. Is de kaart niet toepasselijk is, swipe je deze naar links.',
    },
    {
        index: 2,
        description: 'Je kunt je gekozen kaarten bekijken en wijzigen door op de knop bovenin te klikken.',
    },
    {
        index: 3,
        description: 'Als je alle kaarten gekozen hebt, krijg je een overzicht te zien van al je gekozen kaarten en kun je ze hier bevestigen. Vervolgens krijg je te zien wat je pensioenstatus is.',
    }
];


export default class CardDeck extends React.Component<any, iState> {
    deckRef: any;
    storage: any;
    ArrayCards: any;

    constructor(props: any) {
        super(props);
        this.deckRef = React.createRef();
        this.props.storage.reset();
        this.props.storage.save();
        this.storage = this.props.storage;
        this.state = {
            cardIndex: 0,
            deckOfCards: [],
            lastSwipeDirection: '',
            loading: true,
            preventSwipe: false,
            helpToggle: false,
            currentHelpPage: 1,
            helpAnimation: false,
            helpAnimationButton: false,
            topAnimation: true,
            rotationAnimation: true,
            langEn: localStorage.getItem('lang') && localStorage.getItem('lang') === "en"
        }
        this.arrayCards = new Array(0,1,2,3,4,5,6,7,8,9,10);
    }

    componentDidMount() {
        let t = this;
        this.loadinganimation();

        axios.get('https://cms.pensioenspel.nl/api/cards')
            .then(function (response) {
                // handle success
                // console.log(response);
                document.getElementById("shuffle").volume = 0.2;
                document.getElementById("shuffle").play();
                setTimeout(() => {

                    let array = response.data;

                    var currentIndex = array.length, temporaryValue, randomIndex;

                    while (0 !== currentIndex) {

                        randomIndex = Math.floor(Math.random() * currentIndex);
                        currentIndex -= 1;

                        temporaryValue = array[currentIndex];
                        array[currentIndex] = array[randomIndex];
                        array[randomIndex] = temporaryValue;
                    }

                    t.setState({
                        deckOfCards: array,
                        loading: false
                    })

                }, 2500)
            })

    }

    updateIndex(number: number) {
        this.setState({cardIndex: number});
    }




// style={{transform: 'rotate(' + (0.2 + index / 2) + 'deg)'}}
    render() {

        this.storage.reset();

        if(this.state.loading){
            return <div>
                <div className="loading">{this.state.langEn ? ("Shuffling cards...") : ("Aan het schudden...")}</div>

                <audio id="shuffle" src="./sound/shuffle.mp3" />

                <div className="deck">
                    <div className="fakeStack">
                        {this.arrayCards.map((card: any, index: any) => {
                            return<div className={(this.state.topAnimation ? "fakecard loadingcardanim location rotation": (this.state.rotationAnimation ? "fakecard loadingcardanim rotation" : "fakecard loadingcardanim"))} style={{transform: 'rotate(' + (1.08 * (20 - (Math.floor((index / 10 * 100) / 10)) - index)) + 'deg)', transitionDelay: 0.05 * index +'s'}}/>
                        })
                        }
                    </div>
                </div>

            </div>

        }else{
            if (this.state.deckOfCards.length > 0) {
                return <div>
                    <div className="welniet">
                    <div className="wel">
                        <div className="thumbsup thumbsdown"></div>
                        {this.state.langEn ? ("NO") : ("NIET")}
                    </div>
                    <div className="niet">
                        <div className="thumbsup"></div>
                        {this.state.langEn ? ("YES") : ("WEL")}
                    </div>

                </div>
                    <div className= {'helpBubble '+ ((this.state.helpToggle && !this.state.helpAnimationButton) ? 'cross': 'questionmark') + ((this.state.helpToggle && this.state.helpAnimationButton) ? ' questfade': '')} onClick={() => this.toggleHelp()}>
                    </div>

                    {this.state.helpToggle ? (<div className={(this.state.helpAnimation ? 'helpCard helpCardBackAnimation helpCardAnimation': 'helpCard')}>
                        <div className="helpCardTitle">
                            {this.state.langEn ? ("EXPLANATION") : ("SPELUITLEG")}
                        </div>

                        <Carousel auto widgets={[IndicatorDots]} className={(this.state.helpAnimation ? 'carouselHelp carouselHelpAnimation': 'carouselHelp')}>
                            <div className="helpCardText">
                                {this.state.langEn ? ("When a card does apply to your situation, swipe right. If this is not the case swipe left") : ("Wanneer een kaart van toepassing is, swipe deze dan naar rechts. Indien dit niet het geval is, swipe dan naar links")}

                                <div className="carouselHelpPicture picture1" >
                                    <img height="150px" src={!this.state.langEn ? UitlegSwipe : UitlegSwipeEN} />
                                </div>
                            </div>
                            <div className="helpCardText">
                                {this.state.langEn ? ("To see the backside of the card with extra information, swipe up") : ("Om de achterkant met extra informatie te zien, swipe de kaart dan omhoog")}
                            </div>
                            <div className="helpCardText">
                                {this.state.langEn ? ("After you’ve swiped all the cards, you can find them back in an overview page. Where you can make optionally changes ") : ("Nadat je alle kaarten hebt geswiped, kan je deze terugvinden in een overizcht en hier kun je eventueel jouw keuzes ongedaan maken")}

                                <div className="carouselHelpPicture picture2">
                                    <img height="70px" src={!this.state.langEn ? UitlegTitle : UitlegTitleEN} />
                                </div>
                            </div>
                            <div className="helpCardText">
                                {this.state.langEn ? ("After confirmation of your choices, you directly get an insight in your pension status.") : ("Na het bevestigen van jouw keuzes, krijg je direct inzicht in jouw pensioenstatus.")}
                            </div>
                        </Carousel>

                        <div className="helpCardText">

                        </div>


                    </div>) : ''}



                    {(this.state.helpToggle) ? (<div className={(this.state.helpAnimation ? 'guardrails guardrailsout ': 'guardrails')}>
                    </div>) : ""}

                    <div className="deck" onLoad={() => console.log("Loaded")}>
                        <audio id="swipe" src="./sound/swipe.mp3" />
                        <audio id="turn" src="./sound/turn.mp3" />
                        {this.state.deckOfCards.map((card: Card, index: number) => {

                        // @ts-ignore onClick={this.cardAnimations}
                        if((this.state.deckOfCards.length-this.state.cardIndex - 5) < index && (this.state.deckOfCards.length-this.state.cardIndex + 3) > index){
                            // @ts-ignore
                            return <TinderCard key={"ll"+index} preventSwipe={this.state.preventSwipe ? ["up", "down", "left", "right"] : ["up", "down"]} className={card.turned ? 'card turned '+'card-'+index : 'card '+'card-'+index }
                                               onSwipe={(dir) => this.lastSwipe(dir)}>

                                <div className="cardfront"
                                     style={{backgroundImage: 'url(https://cms.pensioenspel.nl/' + card.image + ')'}}>
                                    <div className="text-top">
                                        <div className="text-front">
                                            {this.state.langEn ? card.text_front_en : card.text_front}
                                        </div>
                                    </div>
                                </div>

                                <div className="cardback">
                                    <div className="cardbacktext">
                                        {this.state.langEn ? card.text_back_en : card.text_back}
                                    </div>
                                </div>
                            </TinderCard>;
                        }else{
                            return '';
                        }

                    })}

                    {this.state.cardIndex >= this.state.deckOfCards.length ? (
                        <div>
                            <h1>
                                {this.state.langEn ? ("All the cards have been chosen") : ("Alle kaarten zijn gekozen!")}
                            </h1>
                            <p>
                                {this.state.langEn ? ("You made a choice for every card. Check above if everything is right. If this is the case, press the button below to confirm everything.") : ("Je hebt voor alle kaarten een keuze gemaakt. Controleer hierboven nog eens of het allemaal klopt. Als dit het geval is, druk op de knop hieronder om alles te bevestigen.")}
                            </p><br /><br />
                            <Link to="/resultaten" className="bigredbuttontwo">{this.state.langEn ? ("Finish Game") : ("Spel Afronden!")}</Link>
                        </div>
                    ) : ''}

                    <div className="fakeStack">
                        {this.state.deckOfCards.slice(0, 10-(Math.floor((this.state.cardIndex / (this.state.deckOfCards.length-1) * 100) / 10))).map((card, index) => {
                            return<div className="fakecard" style={{transform: 'rotate(' + (2.15 * (10 - (Math.floor((this.state.cardIndex / this.state.deckOfCards.length * 100) / 10)) - index)) + 'deg)'}}/>
                        })}
                    </div>
                </div></div>
            } else {
                return <div>
                    {this.state.langEn ? ("Loading...") : ("Aan het laden...")}
                </div>
            }
        }

    }

    private lastSwipe = (dir: string) => {
        this.setState({lastSwipeDirection: dir});

        if (dir === "up") {
            this.rotateCard();
        }

        this.fSwipe(dir);

    }

    private fSwipe = (newDir: string) => {
        if(!this.state.preventSwipe){
            // let dir = this.state.lastSwipeDirection;
            let dir = newDir;
            if (dir === "left") {
                this.swipeLeft();
            }else if (dir === "right") {
                this.swipeRight();
            }
            if(dir === "up"){
                this.playSound("turn");
            }else{
                this.playSound("swipe");
            }
        }

    }

    private swipeLeft = () => {
        let newIndex = this.state.cardIndex;
        // console.log(newIndex);
        this.storage.swipedLeft(this.state.deckOfCards[this.state.deckOfCards.length - newIndex - 1]);
        this.updateIndex(this.state.cardIndex + 1);
    }

    private swipeRight = () => {
        let newIndex = this.state.cardIndex;
        // console.log(newIndex);
        this.storage.swipedRight(this.state.deckOfCards[this.state.deckOfCards.length - newIndex - 1]);
        this.updateIndex(this.state.cardIndex + 1);
    }

    private cardAnimations = (el: any) => {
        let event = el.classList;
        if (!el.classList.contains("rotate")) {
            el.classList.add("rotate");
            setTimeout(() => {
                el.classList.add("rotatez");
            }, 170)

            setTimeout(() => {
                el.classList.add("rotateback");
            }, 160)
            //
            // document.body.classList.add('rotated-cards');
            // event.add("turned");
        }else{
            el.classList.remove("rotateback");
            setTimeout(() => {
                el.classList.remove("rotatez");
            }, 170)

            setTimeout(() => {
                el.classList.remove("rotate");
            }, 160)
            // event.remove("turned");
            // document.body.classList.remove('rotated-cards');
            // el.classList.remove("rotate");
        }

    }

    private playSound = (action: string) => {
        document.getElementById(action).volume = 0.4;
        document.getElementById(action).play();
    }

    private rotateCard = () => {
     //   console.log("Rotate");
        const card = this.state.deckOfCards.length-this.state.cardIndex-1;
        this.cardAnimations(document.getElementsByClassName('card-'+card)[0])
        // console.log(document.getElementsByClassName('card-'+Number(this.state.deckOfCards.length-this.state.cardIndex-1))[0]);
    }

    private toggleHelp = () => {
        if(this.state.helpToggle){
            this.setState({helpAnimation: true});
            this.setState({helpAnimationButton: true});
            setTimeout(() => {
                this.setState({helpToggle: false});
                this.setState({helpAnimation: false});
                this.setState({helpAnimationButton: false});
            }, 790)

        }else{
            this.setState({helpToggle: true});
        }
    }
    private loadinganimation = () => {
        setTimeout(() => {
            this.setState({topAnimation: false});
        }, 100);
        setTimeout(() => {
            this.setState({rotationAnimation: false});
        }, 700);
    }
}
